// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
// import "channels"
import { AsYouType } from "libphonenumber-js/max"

import "@oddcamp/cocoon-vanilla-js";

Rails.start()
ActiveStorage.start()


// import "controllers"
    var initAddressAutocomplete = function (element, country) {
        console.log("INIT ADDRESS AUTO COMPLETE", country)
        let country_code = 'AU';

        switch(country) {
            case 'Australia':
                country_code = 'AU';
                break;
            case 'New Zealand':
                country_code = 'NZ';
                break;
        }
        console.log("INIT ADDRESS AUTO COMPLETE RESTRUCT", country_code)

        let options = {
                componentRestrictions: { country: country_code },
                types: ['address'],
        };

        let address_autocomplete = new google.maps.places.Autocomplete(element, options);
        google.maps.event.clearInstanceListeners(address_autocomplete);

        google.maps.event.addListener(
            address_autocomplete, 
            'place_changed', 
            function(){fillInAddress.call(address_autocomplete, element)}
        );
    },
    fillInAddress = function(element) {
        // Get the place details from the autocomplete object.
        var place = this.getPlace();
        let country = place.address_components.find(x => x.types[0] == 'country').short_name;
        console.log("Found Country", country);
        let address = {}
    
        switch (country) {
            case "AU":
                address = getAUAddressObject(place.address_components);
                break;
            case "NZ":
                address = getNZAddressObject(place.address_components);
                break;
        }
    
        let form = element.closest('.address-fields');
        for(const prop in address) {
            let input = form.querySelector(`.${prop}`);
            if (input) {
                input.value = address[prop];
            }
        }
    }



    function getNZAddressObject(address_components) {
        var ShouldBeComponent = {
            unit: ["subpremise"],
            street_number: ["street_number"],
            street: ["street_address", "route"],
            suburb: ["sublocality_level_1", "sublocality"],
            city: ["locality"],
            region: ["administrative_area_level_1"],
            postal_code: ["postal_code"],
        };
    
        var address = {
            unit: "",
            street_number: "",
            street: "",
            street_address: "",
            suburb: "",
            city: "",
            region: "",
            postal_code: ""
        };
        address_components.forEach(component => {
            for (var shouldBe in ShouldBeComponent) {
              if (ShouldBeComponent[shouldBe].some(x => component.types.includes(x))) {
              //if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
                address[shouldBe] = component.long_name;
              }
            }
        });
        if (address.unit !== "") {
            address.street_address += `${address.unit}/${address.street_number}`
        } else {
            address.street_address += `${address.street_number}`
        }
        address.street_address += ` ${address.street}`
    
        return address;
    }
    
    function getAUAddressObject(address_components) {
        var ShouldBeComponent = {
            unit: ["subpremise"],
            street_number: ["street_number"],
            street: ["street_address", "route", "intersection"],
            suburb: ["locality"],
            region: ["administrative_area_level_1"],
            postal_code: ["postal_code"]
        };
    
        var address = {
            unit: "",
            street_number: "",
            street: "",
            street_address: "",
            suburb: "",
            region: "",
            postal_code: ""
        };
        address_components.forEach(component => {
            for (var shouldBe in ShouldBeComponent) {
              if (ShouldBeComponent[shouldBe].some(x => component.types.includes(x))) {
              //if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
                address[shouldBe] = shouldBe == "region" ? component.short_name : component.long_name;
              }
            }
        });
        if (address.unit !== "") {
            address.street_address += `${address.unit}/${address.street_number}`
        } else {
            address.street_address += `${address.street_number}`
        }
        address.street_address += ` ${address.street}`
    
        return address;
    }
    
    const stored_countries = {};

    const replaceCountryFields = () => {
        document.querySelectorAll(`select.address-country`).forEach(element => {
            let identifier = element.dataset.identifier;

            console.log("replacing ", identifier);

            if (!stored_countries[identifier] || Object.keys(stored_countries[identifier]).length == 0) {
                stored_countries[identifier] = {};
                document.querySelectorAll(`div[data-identifier='${identifier}']`).forEach(country_fields => {
                    stored_countries[identifier][country_fields.dataset.country] = country_fields;
                });
            }

            const countries_node = document.getElementById(`${identifier}_countries`);
            let selected_country = (element.value ?? "Australia").toLowerCase().replace(/ /g, "_");
            console.log("with ", selected_country);
            while (countries_node.firstChild) {
                countries_node.removeChild(countries_node.firstChild);
            }
            countries_node.appendChild(stored_countries[identifier][selected_country]);
        });
    }

    const decorateOptionalSteps = () => {
        let inputs = Array.from(document.querySelectorAll('input:not([type=hidden]),select,textarea'));
        let nextButton = document.querySelector('.text-submit');
        if (inputs.length == 0) {
            nextButton.innerHTML = "Submit";
        }
        else if (inputs.some(x => x.hasAttribute('required'))) {
            nextButton.innerHTML = "Next";
        } else {
            nextButton.innerHTML = "Skip";
        }

        inputs.filter(x => !x.hasAttribute('required')).forEach(element => {
            element.addEventListener("keyup", x => {
                nextButton.innerHTML = "Next";
            })
        });
    }


    let ready = (callback) => {
        if (document.readyState != "loading") callback();
        else document.addEventListener("DOMContentLoaded", callback);
    }

    function toggle_delivery(checked) {
        if (checked) {
            document.querySelectorAll('*[id^=account_application_postal_address_attributes]')
                .forEach(x => x.disabled = true)
        } else {
            document.querySelectorAll('*[id^=account_application_postal_address_attributes]')
                .forEach(x => x.disabled = false)
        }
    }

    ready(() => {
            const otherBusinessNameInnput = document.getElementById("other_business_name");
            const otherBusinessNameRadios = document.getElementsByName("account_application[business_name]");


            if (otherBusinessNameInnput) {
                otherBusinessNameInnput.addEventListener('change', e => {
                    const other = document.getElementById("account_application_business_name_other");
                    other.checked = true;
                    other.value = e.target.value;
                });
            }

            if (otherBusinessNameRadios) {
                console.log(otherBusinessNameRadios)
                const other = document.getElementById("other_business_name");

                for (let i = 0; i < otherBusinessNameRadios.length; i++) {
                    let item = otherBusinessNameRadios[i];

                    if (item.id == "account_application_business_name_other" && item.checked) {
                            other.setAttribute('required','required');
                    } else {
                        other.removeAttribute('required');
                    }
                    
                    item.addEventListener('change', e => {
                        console.log("other change")
                        const other = document.getElementById("other_business_name");

                        if (e.target.id == 'account_application_business_name_other' && e.target.checked) {
                            other.setAttribute('required','required');
                        } else {
                            other.removeAttribute("required");
                        }
                    });
                }

                // if (otherBusinessNameRadio.checked) {
                //     const other = document.getElementById("other_business_name");
                //     other.setAttribute('required','required');

                // } else {
                //     other.setAttribute("required");
                // }

                
            }
            
           

        google.maps.event.addDomListener(window, 'load', function(){
            var inputs=document.querySelectorAll('.address-autocomplete');
            for(var i=0;i<inputs.length;++i){
                let form = inputs.item(i).closest('.address-fields');
                let country = form.querySelector(".address-country");
                console.log("ONLOAD", country.value);

                initAddressAutocomplete(inputs.item(i), country.value);
            }
        });

        const phoneInputs = document.querySelectorAll("[type='tel']");

        phoneInputs.forEach(i => i.addEventListener('keyup', (e) => {
            let formattedNumber = new AsYouType('AU').input(e.currentTarget.value);
            i.value = formattedNumber;
        }));

        toggle_delivery(true);
        replaceCountryFields();
        decorateOptionalSteps();

        let cocoonLinks = document.getElementsByClassName('remove_fields');
        if (cocoonLinks && cocoonLinks.length > 0) {
            console.log("found cocoon links");
            cocoonLinks[0].classList.add('d-none');
            cocoonLinks[0].classList.add('disabled');
        }

        document.addEventListener('change', function(event) {
            if (event.target.classList.contains('address-country')) {
                replaceCountryFields();

                var inputs=document.querySelectorAll('.address-autocomplete');
                for(var i=0;i<inputs.length;++i){
                    let form = inputs.item(i).closest('.address-fields');
                    let country = form.querySelector(".address-country")
                    console.log("CHANGE", country.value);
                    initAddressAutocomplete(inputs.item(i), country.value);
                }

                let input = document.querySelector('input[name="account_application[postal_address_same]"]')
                if (input) {
                    toggle_delivery(input.checked)
                }    
            }

            if (event.target.name == 'account_application[postal_address_same]') {
                toggle_delivery(event.target.checked);
            }
        });

        document.addEventListener('cocoon:after-insert', function(e, insertedItem) {
            replaceCountryFields();

            var inputs=document.querySelectorAll('.address-autocomplete');
                for(var i=0;i<inputs.length;++i){
                    let form = inputs.item(i).closest('.address-fields');
                    let country = form.querySelector(".address-country")
                    initAddressAutocomplete(inputs.item(i), country.value);
                }

        });
    });


